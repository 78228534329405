import React, { useLayoutEffect, useRef, useState } from "react";
import { css } from "goober";

import { IS_NODE } from "@/__main__/constants.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import { APP_SCROLLER } from "@/app/constants.mjs";
import {
  AdsColumn,
  Container,
  DisplayAdBackdrop,
  DisplayAdBase,
  DisplayAdRectangle,
  FULL_HEIGHT_ADS,
  InnerContainer,
} from "@/feature-ads/AdWrapper.style.jsx";
import {
  AD_HEIGHT,
  AD_VIEW_THRESHOLD,
  DISPLAY_AD_CLASS,
  DISPLAY_AD_Z_INDEX,
} from "@/feature-ads/constants/constants.mjs";
import { classNames } from "@/util/class-names.mjs";
import { CONTAINER_ID, OBSERVE_CLASS } from "@/util/exit-transitions.mjs";
import globals from "@/util/global-whitelist.mjs";
import { lastOf } from "@/util/helpers.mjs";
import nextFrame from "@/util/next-frame.mjs";

const MAX_ADS = 3;
const MIN_ADS = 1;

export const ADS_COLUMN_CLASS = "aside-content-column";

const contentRoot = () => globals.document.getElementById(CONTAINER_ID);

function DisplayAd({ id }) {
  return (
    <div {...classNames(DisplayAdBase(), DisplayAdRectangle())}>
      <div className={id} />
    </div>
  );
}

const getMaxAds = (h: number) =>
  Math.max(MIN_ADS, Math.min(MAX_ADS, Math.floor(h / AD_HEIGHT)));

const getOffset = (
  node: HTMLElement,
  direction = node.dataset.direction || "top",
) => {
  switch (direction) {
    case "top":
      return node.offsetTop;
    case "bottom":
      return node.offsetTop + node.offsetHeight;
    case "left":
      return node.offsetLeft;
    case "right":
      return node.offsetLeft + node.offsetWidth;
  }
  return 0;
};

const findAlignElement = (node: Element): HTMLElement => {
  let alignElement = node.classList.contains("sidebar-align")
    ? node
    : // look for the LAST element with the class
      lastOf(node.getElementsByClassName("sidebar-align"));
  while (alignElement instanceof HTMLElement) {
    // in rare cases we need the sidebar align element to persist
    // the usage for this is to add sidebar-align and sidebar-align-child to signal
    // that the parent will be used as the align element, and recalculated when the
    // child is added or removed
    if (alignElement.classList.contains("sidebar-align-child")) {
      alignElement = alignElement.parentElement;
      continue;
    }
    const sticky =
      getComputedStyle(alignElement).position === "sticky" || // css sticky
      alignElement.dataset.sticky === "true"; // data-sticky
    if (!sticky) break;
    alignElement = alignElement.nextElementSibling;
  }
  return alignElement instanceof HTMLElement ? alignElement : null;
};

const getHeaderMaxHeight = () => {
  const stickies = globals.document
    .querySelector<HTMLElement>(
      // must select inside observe class because page header
      // may be in transition
      `.${OBSERVE_CLASS} .page-header`,
    )
    ?.parentNode.querySelectorAll<HTMLElement>(
      "[data-sticky-cumulative-height]",
    );
  const lastSticky = stickies?.[stickies.length - 1];
  return lastSticky?.dataset.stickyCumulativeHeight || 0;
};

const noAnimation = (node: HTMLElement) =>
  node.style.setProperty("--transition", "0s");
const allowAnimation = (node: HTMLElement) =>
  node.style.removeProperty("--transition");

const cssOverrides = () => css`
  .links-container {
    position: static !important;
  }
`;

export function ContentWrapper({ children }) {
  const y = useRef(0);
  const [adCount, setAdCount] = useState(MAX_ADS);

  const updateMaxAds = () => {
    if (!colRef.current) return;
    const content = globals.document.querySelector<HTMLElement>(
      `#${CONTAINER_ID}`,
    );
    const offset = content ? content.offsetTop : 0;
    const height = FULL_HEIGHT_ADS
      ? globals.innerHeight
      : globals.innerHeight - offset - y.current;

    setAdCount((prev) => {
      const newCount = getMaxAds(height);
      if (prev === newCount) return prev;
      setVolatileKV("colAdsCount", newCount);
      return newCount;
    });
    noAnimation(colRef.current);
  };

  // align the ad column to the start of page content
  const [shouldAlignContent, setShouldAlignContent] = useState(false);

  const colRef = useRef(null);

  useLayoutEffect(() => {
    const col = colRef.current;
    if (!col) return;

    // prevent all transitions on first render
    noAnimation(col);
    nextFrame(() => allowAnimation(col));

    const setYOffset = (value: number, instant = false) => {
      if (!shouldAlignContent) {
        col.style.setProperty("--content-start", "");
        col.style.setProperty("--sticky-header-height", "");
        return;
      }
      y.current = value;
      col.style.setProperty("--content-start", `${value}px`);
      if (!instant) allowAnimation(col);
      // update the sticky header height
      // we do not have to do it in this setter BUT we do NOT want to make this
      // reactive on header height in volatile state as it will cause display flicker
      // while JS is catching up; we always want to set this to the max stuck height.
      const headerHeight = getHeaderMaxHeight();
      col.style.setProperty("--sticky-header-height", `${headerHeight}px`);
    };

    // the ad to the sidebar-align element
    const content = contentRoot();

    const alignElement =
      content &&
      (findAlignElement(content) ||
        content.querySelector<HTMLElement>(`.${OBSERVE_CLASS}`));
    if (alignElement) setYOffset(getOffset(alignElement), true);

    const alignSizeObs = new ResizeObserver(([entry]) => {
      if (!(entry.target instanceof HTMLElement)) return;
      setYOffset(getOffset(entry.target));
      updateMaxAds();
    });
    alignSizeObs.observe(alignElement);

    const setAlignElement = (el: HTMLElement) => {
      alignSizeObs.disconnect();
      alignSizeObs.observe(el);
      // trigger this early to re-render immediately
      setYOffset(getOffset(el));
      // add align class so we can watch for when this node is removed
      el.classList.add("sidebar-align");
    };
    // find new align elements in page content
    const alignObs = new MutationObserver((mutations) => {
      for (const { addedNodes, removedNodes } of mutations) {
        for (const node of addedNodes) {
          if (!(node instanceof HTMLElement) || node.inert) continue;
          const alignElement = findAlignElement(node);
          if (alignElement) setAlignElement(alignElement);
        }
        for (const node of removedNodes) {
          if (!(node instanceof Element && findAlignElement(node))) continue;
          const newAlignment = findAlignElement(content);
          if (newAlignment) setAlignElement(newAlignment);
        }
      }
    });
    alignObs.observe(content, {
      childList: true,
      subtree: true,
    });

    const viewabilityObs = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const method = entry.isIntersecting ? "add" : "remove";
          entry.target.classList[method]("viewable");
        }
      },
      { threshold: AD_VIEW_THRESHOLD },
    );
    for (const ad of col.getElementsByClassName(DISPLAY_AD_CLASS)) {
      viewabilityObs.observe(ad);
    }
    return () => {
      alignObs.disconnect();
      alignSizeObs.disconnect();
      viewabilityObs.disconnect();
    };
  }, [shouldAlignContent]);

  // Check if the whole ads column has enough leeway to align vertically.
  useLayoutEffect(() => {
    if (IS_NODE) return;

    function resizeListener() {
      const adsElement = globals.document.querySelector(`.${ADS_COLUMN_CLASS}`);
      if (!adsElement) {
        return;
      }
      const rootElement = contentRoot();
      const top = 0;
      // const { top } = rootElement.getBoundingClientRect();
      const { height } = adsElement.getBoundingClientRect();
      const alignElement = findAlignElement(rootElement);
      if (!alignElement) {
        return;
      }
      const { top: alignTop } = alignElement.getBoundingClientRect();
      const [scroller] = globals.document.getElementsByClassName(APP_SCROLLER);
      const value =
        alignTop + top + height + scroller.scrollTop < globals.innerHeight;

      setShouldAlignContent(value);
    }

    nextFrame(resizeListener);

    globals.addEventListener("resize", resizeListener);

    return () => {
      globals.removeEventListener("resize", resizeListener);
    };
  }, []);

  // right nav should have alternate view when expanding will obscure ads
  useLayoutEffect(() => {
    const [root] = globals.document.getElementsByClassName(APP_SCROLLER);

    const observersCallback = () => {
      updateMaxAds();
    };

    const resizeObserver = new ResizeObserver(observersCallback);
    resizeObserver.observe(root);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Container>
      <div
        style={{
          "--count": adCount,
          zIndex: DISPLAY_AD_Z_INDEX,
        }}
        {...classNames(ADS_COLUMN_CLASS, AdsColumn())}
        ref={colRef}
      >
        <DisplayAd id={"clever-core-ads"} />
        <DisplayAd id={"clever-core-ads"} />
        <DisplayAd id={"clever-core-ads"} />
        {/* Label must be "Advertisements" or "Sponsored Links". No other variations are allowed at this time.
              See https://support.google.com/adsense/answer/4533986?hl=en */}
        <div className={DisplayAdBackdrop()} data-label={"Advertisements"} />
      </div>
      <InnerContainer className={`inner-wrapper-col ${cssOverrides()}`}>
        {children}
      </InnerContainer>
    </Container>
  );
}
