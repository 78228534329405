import { css, styled } from "goober";

export const NavDropdown = styled("button")`
  position: relative;
  padding: 0;
  background: none;

  .nav-dropdown-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > img {
      width: 147px;
      height: var(--sp-8);
    }

    & > svg {
      height: var(--sp-5);
      width: var(--sp-5);
      transition: transform 0.2s ease-in-out;
    }

    &.open {
      & > svg {
        transform: rotate(180deg);
      }
    }
  }

  .nav-dropdown {
    display: flex;
    width: 350px;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    color: var(--shade0);

    .nav-items {
      display: none;
      flex-direction: column;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        height: var(--sp-7);
        width: 100%;

        a {
          padding-left: var(--sp-6);
        }

        &.primary {
          display: flex;
          align-items: center;
          padding: 0 var(--sp-6);

          a {
            padding: 0;
          }

          span {
            margin: 0;
            text-align: center;
          }
        }
      }
    }

    img {
      height: var(--sp-8);
      width: var(--sp-8);
    }

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: var(--sp-12);

      &.divider {
        height: 1px;
        padding-bottom: 1px;
        background-color: var(--shade6);
        margin: var(--sp-3) 0;
      }

      &.section-title {
        height: var(--sp-7);
        padding: var(--sp-2) var(--sp-5);
        text-transform: uppercase;
        font-size: var(--sp-3);
        font-weight: 700;
        letter-spacing: 0.06em;
        color: var(--shade3);
      }

      a {
        display: flex;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        align-items: center;
        padding: 0 var(--sp-4);
        overflow: hidden;
        margin-bottom: var(--sp-2);

        svg {
          height: var(--sp-7);
          width: var(--sp-7);
        }

        span {
          display: inline-block;
          margin-left: var(--sp-3);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: left;
        }

        &:hover {
          background-color: var(--shade9);
          color: var(--shade0);
        }
      }
    }
  }

  @media (max-width: 950px) {
    .nav-items.left {
      display: flex;
    }
  }
  @media (max-width: 600px) {
    .nav-items.right {
      display: flex;
    }
  }
  @media (max-width: 414px) {
    .nav-dropdown {
      max-width: 100vw;
      width: 100%;
      height: 100%;
      min-height: calc(100vh - var(--sp-14));

      .desktop-only {
        display: none;
      }
    }
  }
`;

export const dialogCssOverride = () => css`
  margin: 0 !important;
  padding: var(--sp-4) var(--sp-4) !important;
  left: var(--page-padding) !important;
  top: var(--sp-15) !important;
  background-color: var(--shade10) !important;
  drop-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  border-radius: var(--sp-2) !important;

  &::backdrop {
    background-color: transparent !important;
  }

  @media (min-width: 1440px) {
    left: calc((100vw - 1440px) / 2 + 14px) !important;
  }

  @media (max-width: 414px) {
    left: 0 !important;
    top: var(--sp-14) !important;
    max-width: 100vw !important;
    height: 100vh !important;
    width: 100% !important;
    border-radius: 0 !important;
  }
`;
