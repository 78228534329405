import { subscribeKey } from "valtio/utils";

import { readState } from "@/__main__/app-state.mjs";
import { IS_NODE } from "@/__main__/constants.mjs";
import mainRefs from "@/__main__/refs.mjs";
import { appURLs, GAME_ACTIVE_MAP } from "@/app/constants.mjs";
import { ContentWrapper } from "@/feature-reskin-champion-gg/ContentWrapper.jsx";
import { FooterLogo } from "@/feature-reskin-champion-gg/Footer.jsx";
import { HeaderWrapper } from "@/feature-reskin-champion-gg/HeaderWrapper.jsx";
import { NavigationWrapper } from "@/feature-reskin-champion-gg/NavigationWrapper.jsx";
import reskinRefs from "@/feature-reskin-champion-gg/refs.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import {
  championRedirectRoute,
  championRoute,
  championsRedirectRoute,
  championsRoute,
  lolChampTabs,
  overallTabs,
  probuildsRedirectRoute,
  probuildsRoute,
  tierlistRoute,
} from "@/game-lol/routes.mjs";
import footerRefs from "@/marketing/footer.refs.mjs";
import gameRoutes from "@/routes/game.mjs";
import { homepageRoutes } from "@/routes/marketing.mjs";
import routes from "@/routes/routes.mjs";
import appRouteRefs from "@/util/app-route.refs.mjs";
import deepEqual from "@/util/deep-equal.mjs";
import globals from "@/util/global-whitelist.mjs";
import { removeFromArray } from "@/util/helpers.mjs";
import lazyFn from "@/util/lazy-fn.mjs";
import mapOriginalRefs, {
  forceOverwriteIterable,
} from "@/util/map-original-refs.mjs";
import pathRegExp from "@/util/path-regexp.mjs";

const original = mapOriginalRefs({
  mainRefComponents: mainRefs.components,
  appRouteRefs,
  mainRefs,
  routes,
  footerRefs,
  lolRefs,
  reskinRefs,
  appURLs,
});

const lolFetchStaticData = lazyFn(
  () => import("@/game-lol/fetches/lol-fetch-static-data.mjs"),
);

const lolFetchChampionsData = lazyFn(
  () => import("@/game-lol/fetches/lol-fetch-champions-data.mjs"),
);

const newChampionsRoute = {
  ...championsRoute,
  path: pathRegExp(`/statistics/(${overallTabs})`),
};

const newChampionRoute = {
  ...championRoute,
  path: (() => {
    return new RegExp(
      `^/champion/((?!(${overallTabs}))\\w+)(/(${lolChampTabs})(/(\\w*))?)$`, // eslint-disable-line no-useless-escape
    );
  })(),
};

const newProbuildsRoute = {
  ...probuildsRoute,
  path: "/probuilds",
};

function findRouteIndex(match) {
  return routes.findIndex((route) => {
    return deepEqual(match, route);
  });
}

const memoizedActiveGames = {};
let unsubscribe;

export function setup() {
  const homepageIndex = findRouteIndex(homepageRoutes[0]);
  const redirectChampionsIndex = findRouteIndex(championsRedirectRoute);
  const redirectChampionIndex = findRouteIndex(championRedirectRoute);
  const overallChampionsIndex = findRouteIndex(championsRoute);
  const individualChampionIndex = findRouteIndex(championRoute);
  const tierlistIndex = findRouteIndex(tierlistRoute);
  const probuildsIndexRedirect = findRouteIndex(probuildsRedirectRoute);
  const probuildsIndex = findRouteIndex(probuildsRoute);

  if (!IS_NODE) {
    if (readState.volatile.shouldHaveAds) {
      injectScript(true);
    } else {
      unsubscribe = subscribeKey(
        readState.volatile,
        "shouldHaveAds",
        injectScript,
      );
    }
  }

  const siteName = ["championgg:name", "Champion.gg"];
  siteName[forceOverwriteIterable] = true;

  original.set({
    appURLs: {
      BLITZ: "https://champion.gg",
    },
    mainRefs: {
      DEFAULT_META_IMAGE: {
        url: `${appURLs.CDN_PLAIN}/championgg/og-image.png`,
        alt: ["championgg:championggLogo", "Champion.GG Logo"],
        width: 640,
        height: 430,
      },
      DEFAULT_SITE: siteName,
    },
    reskinRefs: {
      activeGameSet: new Set(
        Object.getOwnPropertySymbols(GAME_ACTIVE_MAP).filter(
          (symbol) => GAME_ACTIVE_MAP[symbol],
        ),
      ),
    },
    lolRefs: {
      lolChampionsPrefix: "statistics",
      lolChampionPrefix: "champion",
      lolTierlistPrefix: "tierlist",
    },
    footerRefs: {
      Logo: FooterLogo,
    },
    mainRefComponents: {
      HeaderWrapper,
      ContentWrapper,
      NavigationWrapper,
    },
    appRouteRefs: {
      pageHeaderOverride: true,
    },
    routes: {
      [homepageIndex]: {
        ...routes[homepageIndex],
        hubSymbol: null,
        component: "feature-reskin-champion-gg/Home.tsx",
        fetchData(params, ...args) {
          return Promise.all([
            lolFetchStaticData(),
            lolFetchChampionsData(["overview"], ...args),
          ]);
        },
      },
      [redirectChampionsIndex]: {
        path: "/statistics",
        redirect: "/statistics/overview",
      },
      [redirectChampionIndex]: {
        path: new RegExp(
          `^/champion/(\\w+)/?(\\w+)?$`, // eslint-disable-line no-useless-escape
        ),
        redirect: ({ parameters: [championKey, role] }) => {
          const searchParams = new URLSearchParams();

          if (role && role !== "overview") {
            searchParams.set("role", role);
          }

          return `/champion/${championKey}/build${
            searchParams.size ? `?${searchParams}` : ""
          }`;
        },
      },
      [tierlistIndex]: {
        ...tierlistRoute,
        path: pathRegExp("/tierlist{/:tab}?"),
      },
      [probuildsIndexRedirect]: {
        ...probuildsRedirectRoute,
        path: "/probuilds",
        redirect: "/probuilds/history",
      },
      [overallChampionsIndex]: newChampionsRoute,
      [individualChampionIndex]: newChampionRoute,
      [probuildsIndex]: newProbuildsRoute,
    },
  });

  // The following is not handled by mapOriginalRefs.
  // ==================================================

  gameRoutes[GAME_SYMBOL_LOL].push(newChampionsRoute, newChampionRoute);

  for (const symbol of Object.getOwnPropertySymbols(GAME_ACTIVE_MAP)) {
    memoizedActiveGames[symbol] = GAME_ACTIVE_MAP[symbol];
    if (symbol === GAME_SYMBOL_LOL) continue;
    GAME_ACTIVE_MAP[symbol] = false;
  }
}

export function teardown() {
  unsubscribe?.();
  original.restore();

  const lolRoutes = gameRoutes[GAME_SYMBOL_LOL];
  removeFromArray(lolRoutes, newChampionsRoute);
  removeFromArray(lolRoutes, newChampionRoute);

  for (const symbol of Object.getOwnPropertySymbols(GAME_ACTIVE_MAP)) {
    GAME_ACTIVE_MAP[symbol] = memoizedActiveGames[symbol];
  }
}

const injectScript = (hasAdsEnabled) => {
  if (!hasAdsEnabled) return;
  const script = globals.document.createElement("script");
  script.dataset.cfasync = false;
  script.type = "text/javascript";
  script.id = "clever-core";
  script.textContent = `
    (async function (document, window) {
      if (document.visibilityState !== "visible") {
        await new Promise((resolve) => {
          document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") resolve();
          });
        });
      }

      var a, c = document.createElement("script"), f = window.frameElement;

      c.id = "CleverCoreLoader50506";
      c.src = "https://scripts.cleverwebserver.com/3aa6ec91cddc6c724c24c73f92d21187.js";

      c.async = !0;
      c.type = "text/javascript";
      c.setAttribute("data-target", window.name || (f && f.getAttribute("id")));
      // c.setAttribute("data-callback", "put-your-callback-function-here");
      // c.setAttribute("data-callback-url-click", "put-your-click-macro-here");
      // c.setAttribute("data-callback-url-view", "put-your-view-macro-here");

      try {
          a = parent.document.getElementsByTagName("script")[0] || document.getElementsByTagName("script")[0];
      } catch (e) {
          a = !1;
      }

      a || (a = document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]);
      a.parentNode.insertBefore(c, a);
    })(document, window);
  `;
  globals.document.body.appendChild(script);
};
