import React from "react";
import { styled } from "goober";

import { appURLs } from "@/app/constants.mjs";

const Logo = styled("img")`
  width: 240px;
  aspect-ratio: 320 / 80;
`;

export function FooterLogo() {
  return (
    <Logo
      src={`${appURLs.CDN}/championgg/img/champion-gg-logo.png`}
      alt="Champion.gg logo"
    />
  );
}
