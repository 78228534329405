import React, { useCallback, useRef, useState } from "react";
import { Modal } from "clutch/src/Modal/Modal.jsx";
import { t } from "i18next";

import { appURLs, GAME_NAME_MAP, GAME_SHORT_NAMES } from "@/app/constants.mjs";
import {
  dialogCssOverride,
  NavDropdown,
} from "@/feature-reskin-champion-gg/HeaderNavDropdown.style.jsx";
import {
  LeftNav,
  RightNav,
} from "@/feature-reskin-champion-gg/HeaderWrapper.jsx";
import refs from "@/feature-reskin-champion-gg/refs.mjs";
import ChevronDown from "@/inline-assets/chevron-down.svg";
import { GameIcon } from "@/shared/GameFilterBar.jsx";
import { classNames } from "@/util/class-names.mjs";

function GameListItem(symbol) {
  return (
    <li key={GAME_SHORT_NAMES[symbol]}>
      <a href={`https://blitz.gg/${GAME_SHORT_NAMES[symbol]}`}>
        <div>{<GameIcon symbol={symbol} />}</div>
        <span>{t(...GAME_NAME_MAP[symbol])}</span>
      </a>
    </li>
  );
}

export function HeaderNavDropdown() {
  const modalRef = useRef<HTMLDialogElement>();
  const [open, setOpen] = useState(false);

  const handleToggleModal = useCallback(() => {
    setOpen(!open);
    if (modalRef.current) {
      if (open) {
        modalRef.current.close();
      } else {
        modalRef.current.showModal();
      }
    }
  }, [open]);

  return (
    <NavDropdown onClick={handleToggleModal}>
      <div {...classNames("nav-dropdown-trigger", open && "open")}>
        <img
          src={`${appURLs.CDN}/championgg/img/champion-gg-logo.png`}
          alt="Champion.gg logo"
        />
        <ChevronDown />
      </div>
      <Modal className={dialogCssOverride()} ref={modalRef}>
        <ul className="nav-dropdown">
          <li className="desktop-only">
            <a href="/">
              <div>
                <img
                  src={`${appURLs.CDN}/championgg/img/champion-gg-icon.png`}
                />
              </div>
              <span>{t("championgg:nav.championgg", "ChampionGG")}</span>
            </a>
          </li>
          <LeftNav />
          <RightNav />
          <li className="divider" />
          <li className="section-title">
            {t("championgg:nav.blitz", "Blitz")}
          </li>
          {Array.from(refs.activeGameSet).map((symbol) => GameListItem(symbol))}
          <li className="divider" />
          <li>
            <a href="https://probuilds.net">
              <div>
                <img src={`${appURLs.CDN}/championgg/img/pb.png`} />
              </div>
              <span>{t("championgg:nav.probuilds", "Probuilds")}</span>
            </a>
          </li>
        </ul>
      </Modal>
    </NavDropdown>
  );
}
